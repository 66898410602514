"use client"; // Error components must be Client Components

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import ErrorView from "@/components/misc/ErrorView";
import {
  isZendeskLoaded,
  hideZendeskWidget,
} from "@/lib/utils/helpers/zendesk";

/**
 * @param {{
 *  error:  Error & { digest?: string },
 *  reset: () => void
 * }} param0
 * @returns
 */
export default function DefaultError({ error, reset }) {
  const pathname = usePathname();
  useEffect(() => {
    // Log the error to an error reporting service
    console.error("Pathname: ", pathname);
    console.error("Error: ", pathname, error);
    if (isZendeskLoaded()) {
      hideZendeskWidget();
    }
  }, [error]);

  return <ErrorView reset={reset} />;
}
