"use client";

import Link from "next/link";
import Button from "../common/shared/Button/button";

const ErrorView = ({ reset }) => {
  return (
    <section className="signup register">
      <div className="container">
        <div className="row">
          <div className="col w-12/12">
            <div className="formBox noBoxShadow">
              <div className="form-inner-box back-login-border  bg-tertiary-white flex flex-col items-center justify-center mx-auto w-12/12">
                <h4 className="lg:text-headline-4 xs:text-headline-5-b text-headline-5 mb-2 text-primary-blue-900 xs:mt-44 mt-24">
                  Something went wrong!
                </h4>
                <p className="text-body-text-2 text-primary-black-800 text-center">
                  Application error: (see the browser console for more
                  information)
                </p>
                <div className="flex flex-col w-full items-center md:w-6/12 xs:mt-44 mt-[28px] sm:flex-row gap-6">
                  <Button
                    type="button"
                    theme="blue-btn"
                    onClick={reset}
                    className="max-w-[320px]"
                  >
                    Try again
                  </Button>
                  <Link
                    href="/"
                    prefetch={false}
                    className="font-semibold blue-btn max-w-[320px]"
                  >
                    Back To Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorView;
